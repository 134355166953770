@charset "UTF-8";
.ReactModal__Body--open .menu-voice, .ReactModal__Body--open .show-me--container {
  z-index: -1 !important; }

.show-me {
  color: white;
  text-transform: uppercase;
  margin-right: 10px; }

.show-me, .show-me-photos {
  font-size: 1em; }
  @media all and (min-width: 980px) {
    .show-me, .show-me-photos {
      font-size: 2em; } }

.show-me--container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  background-color: black; }

.show-me-photos {
  color: white;
  cursor: pointer; }
  .show-me-photos.active {
    color: red; }
  .show-me-photos:after {
    margin: 0 10px;
    content: "–"; }

.photos--container {
  width: 100%;
  height: 100%; }

.photo--instance {
  position: absolute;
  max-width: 200px; }
  @media all and (min-width: 980px) {
    .photo--instance {
      max-width: 600px; } }
  @media all and (min-width: 1281px) {
    .photo--instance {
      max-width: 800px; } }
  .photo--instance img, .photo--instance source {
    width: 100%; }
  .photo--instance img {
    border: 10px solid blue; }
    @media all and (min-width: 980px) {
      .photo--instance img {
        border-width: 20px; } }

.picture--zoom {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 40px;
  font-size: 90px;
  color: yellow; }

.ReactModal__Overlay {
  z-index: 10000; }

.photo--modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center; }

.close--modal {
  position: absolute;
  top: 20px;
  left: 120px;
  font-size: 90px;
  color: yellow;
  cursor: pointer; }
