body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

html, body {
  background: black;
}

h1, h2, h3, h4, h5, h6, p, a, span, strong, em, b, i {
  margin: 0;
}

.video-player--wrapper {
  width: 100%;
  height: 100vh;
}
.home--container {
  width: 100vw;
  height: 100vh; }

.menu--container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%; }

.logo--container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: difference; }
  .logo--container h1 {
    text-transform: uppercase;
    font-size: 150px;
    color: white; }

.cta--container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 70px; }

.home--image {
  position: absolute; }
  .home--image source, .home--image img {
    width: 500px; }

.show-me--container, .menu-voice {
  z-index: 100000; }

.menu-voice {
  position: relative;
  top: 40px;
  width: 12%;
  text-transform: uppercase;
  word-break: break-word;
  cursor: pointer;
  font-size: 18px; }
  @media all and (min-width: 980px) {
    .menu-voice {
      font-size: 26px; } }
  .menu-voice h1 {
    font-size: inherit;
    position: absolute;
    display: block; }
  .menu-voice .menu-voice--first {
    z-index: 3; }
  .menu-voice .menu-voice--second {
    top: 4px;
    z-index: 2; }
  .menu-voice .first-color {
    color: red; }
  .menu-voice .second-color {
    color: white; }
  .menu-voice .menu-voice--third {
    top: 8px;
    z-index: 1; }

.menu-voice--left {
  left: 60px; }
  .menu-voice--left .menu-voice--first {
    left: 8px; }
  .menu-voice--left .menu-voice--second {
    left: 4px; }
  .menu-voice--left .menu-voice--third {
    left: 0; }
  .menu-voice--left .third-color {
    color: yellow; }

.menu-voice--right {
  right: 60px; }
  .menu-voice--right .menu-voice--first {
    right: 8px; }
  .menu-voice--right .menu-voice--second {
    right: 4px; }
  .menu-voice--right .menu-voice--third {
    right: 0; }
  .menu-voice--right .third-color {
    color: blue; }

.watch-the-video {
  position: absolute;
  left: 50%;
  top: 40px;
  width: 16%;
  margin-left: -8%;
  cursor: pointer; }
  .watch-the-video h1 {
    position: absolute;
    text-transform: uppercase;
    font-size: 18px; }
    @media all and (min-width: 980px) {
      .watch-the-video h1 {
        font-size: 26px; } }
  .watch-the-video .first {
    margin-top: 10px; }
  .watch-the-video .second {
    margin-top: 5px; }
  .watch-the-video .third {
    z-index: 3; }
  .watch-the-video .white {
    color: white; }
  .watch-the-video .blue {
    color: blue; }
  .watch-the-video .red {
    color: red; }
  .watch-the-video .green {
    color: #00ff00; }

.video-js {
  outline: 0; }

.video-js--container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 40px solid #373636; }

.video-js {
  height: 100%;
  width: 100%; }

.video-js--top-container {
  top: 0;
  z-index: 1; }
  .video-js--top-container .close {
    position: absolute;
    top: 30px;
    font-size: 16px;
    left: 10px;
    color: white;
    cursor: pointer; }
    @media all and (min-width: 980px) {
      .video-js--top-container .close {
        top: 10px;
        font-size: 28px; } }
  .video-js--top-container h1 {
    font-size: 12px; }
    @media all and (min-width: 980px) {
      .video-js--top-container h1 {
        font-size: 24px; } }
  .video-js--top-container .h1--fullscreen {
    color: red; }
  .video-js--top-container .h1--volume {
    color: #00ff00; }
  .video-js--top-container .h1--headphone {
    color: blue; }

.video-js--top-container,
.video-js--buttons-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-transform: uppercase; }

.video-js--buttons-container {
  bottom: 0; }
  .video-js--buttons-container .play, .video-js--buttons-container .fullscreen, .video-js--buttons-container .pause {
    font-weight: bold;
    margin: 0 10px;
    font-size: 20px; }
  .video-js--buttons-container .play {
    cursor: pointer;
    color: red; }
  .video-js--buttons-container .pause {
    cursor: pointer;
    color: #00ff00; }
  .video-js--buttons-container .fullscreen {
    cursor: pointer;
    color: blue; }

@charset "UTF-8";
.ReactModal__Body--open .menu-voice, .ReactModal__Body--open .show-me--container {
  z-index: -1 !important; }

.show-me {
  color: white;
  text-transform: uppercase;
  margin-right: 10px; }

.show-me, .show-me-photos {
  font-size: 1em; }
  @media all and (min-width: 980px) {
    .show-me, .show-me-photos {
      font-size: 2em; } }

.show-me--container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  background-color: black; }

.show-me-photos {
  color: white;
  cursor: pointer; }
  .show-me-photos.active {
    color: red; }
  .show-me-photos:after {
    margin: 0 10px;
    content: "\2013"; }

.photos--container {
  width: 100%;
  height: 100%; }

.photo--instance {
  position: absolute;
  max-width: 200px; }
  @media all and (min-width: 980px) {
    .photo--instance {
      max-width: 600px; } }
  @media all and (min-width: 1281px) {
    .photo--instance {
      max-width: 800px; } }
  .photo--instance img, .photo--instance source {
    width: 100%; }
  .photo--instance img {
    border: 10px solid blue; }
    @media all and (min-width: 980px) {
      .photo--instance img {
        border-width: 20px; } }

.picture--zoom {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 40px;
  font-size: 90px;
  color: yellow; }

.ReactModal__Overlay {
  z-index: 10000; }

.photo--modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center; }

.close--modal {
  position: absolute;
  top: 20px;
  left: 120px;
  font-size: 90px;
  color: yellow;
  cursor: pointer; }

.about--container {
  width: 100%;
  height: 100%; }
  @media all and (min-width: 980px) {
    .about--container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; } }

.square {
  width: 200px;
  height: 200px;
  border: 10px solid;
  padding: 5px; }
  @media all and (max-width: 979px) {
    .square {
      margin: 10px auto !important; } }
  @media all and (min-width: 980px) {
    .square {
      width: 280px;
      height: 280px; } }
  .square.red {
    border-color: red; }
    @media all and (max-width: 979px) {
      .square.red {
        margin-top: 150px !important; } }
  .square.green {
    border-color: #00ff00; }
    @media all and (min-width: 980px) {
      .square.green {
        margin: 0 20px; } }
  .square.blue {
    border-color: blue; }
  .square h3 {
    color: white;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-size: 14px; }
    @media all and (min-width: 980px) {
      .square h3 {
        font-size: 22px; } }
  .square p {
    color: white;
    line-height: 1.4;
    font-weight: bold;
    font-style: italic;
    font-size: 12px; }
    @media all and (min-width: 980px) {
      .square p {
        font-size: 15px; } }

