$y-distance: 4;
$x-distance: 8;

.show-me--container, .menu-voice {
  z-index: 100000;
}

.menu-voice {
  //position: absolute;
  position: relative;
  top: 40px;
  width: 12%;
  text-transform: uppercase;
  word-break: break-word;
  cursor: pointer;
  font-size: 18px;
  @media all and (min-width: 980px) {
    font-size: 26px;
  }
  h1 {
    font-size: inherit;
    position: absolute;
    display: block;
  }
  .menu-voice--first {
    z-index: 3;
  }
  .menu-voice--second {
    top: #{$y-distance}px;
    z-index: 2;
  }
  .first-color {
    color: red;
  }
  .second-color {
    color: white;
  }
  .menu-voice--third {
    top: #{$y-distance * 2}px;
    z-index: 1;
  }
}

.menu-voice--left {
  left: 60px;
  .menu-voice--first {
    left: #{$x-distance}px;
  }
  .menu-voice--second {
    left: #{$x-distance/2}px;
  }
  .menu-voice--third {
    left: 0;
  }
  .third-color {
    color: yellow;
  }
}

.menu-voice--right {
  right: 60px;
  .menu-voice--first {
    right: #{$x-distance}px;
  }
  .menu-voice--second {
    right: #{$x-distance/2}px;
  }
  .menu-voice--third {
    right: 0;
  }
  .third-color {
    color: blue;
  }
}