body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

html, body {
  background: black;
}

h1, h2, h3, h4, h5, h6, p, a, span, strong, em, b, i {
  margin: 0;
}

.video-player--wrapper {
  width: 100%;
  height: 100vh;
}