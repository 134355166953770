.home--container {
  width: 100vw;
  height: 100vh;
}

.menu--container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  //padding: 40px 60px 0;
}

.logo--container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: difference;
  h1 {
    text-transform: uppercase;
    font-size: 150px;
    color: white;
    //letter-spacing: -4px;
  }
}

.cta--container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 70px;
}

.home--image {
  position: absolute;
  //visibility: hidden;
  source, img {
    width: 500px;
  }
}