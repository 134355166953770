@import "../variables";

.about--container {
  width: 100%;
  height: 100%;

  @media all and (min-width: 980px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.square {
  width: 200px;
  height: 200px;
  border: 10px solid;
  padding: 5px;
  @media all and (max-width: 979px) {
    margin: 10px auto !important;
  }
  @media all and (min-width: 980px) {
    width: 280px;
    height: 280px;
  }
  &.red {
    @media all and (max-width: 979px) {
      margin-top: 150px !important;
    }
    border-color: red;
  }
  &.green {
    border-color: $green;
    @media all and (min-width: 980px) {
      margin: 0 20px;
    }
  }
  &.blue {
    border-color: blue;
  }
  h3 {
    color: white;
    text-transform: uppercase;
    padding-bottom: 5px;
    font-size: 14px;
    @media all and (min-width: 980px) {
      font-size: 22px;
    }
  }
  p {
    color: white;
    line-height: 1.4;
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    @media all and (min-width: 980px) {
      font-size: 15px;
    }
  }
}