@import "../variables";

$y-distance: 5;
$x-distance: 10;

.watch-the-video {
  //display: flex;
  //justify-content: center;
  position: absolute;
  left: 50%;
  top: 40px;
  width: 16%;
  margin-left: -8%;
  cursor: pointer;
  h1 {
    position: absolute;
    text-transform: uppercase;
    font-size: 18px;
    @media all and (min-width: 980px) {
      font-size: 26px;
    }
  }
  .first {
    margin-top: #{$y-distance*2}px;
  }
  .second {
    margin-top: #{$y-distance}px;
    //margin-left: -#{$x-distance/2}px;
  }
  .third {
    //margin-left: -#{$x-distance}px;
    z-index: 3;
  }
  .white {
    color: white;
  }
  .blue {
    color: blue;
  }
  .red {
    color: red;
  }
  .green {
    color: $green;
  }
}