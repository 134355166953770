@import "../variables";

.video-js {
  outline: 0;
}

.video-js--container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 40px solid $gray;
}

.video-js {
  height: 100%;
  width: 100%;
}

.video-js--top-container {
  top: 0;
  z-index: 1;
  .close {
    position: absolute;
    top: 30px;
    font-size: 16px;
    @media all and (min-width: 980px) {
      top: 10px;
      font-size: 28px;
    }
    left: 10px;
    color: white;
    cursor: pointer;
  }
  h1 {
    font-size: 12px;
    @media all and (min-width: 980px) {
      font-size: 24px;
    }
  }
  .h1--fullscreen {
    color: red;
  }
  .h1--volume {
    color: $green;
  }
  .h1--headphone {
    color: blue;
  }
}

.video-js--top-container,
.video-js--buttons-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-transform: uppercase;
}

.video-js--buttons-container {
  bottom: 0;
  .play, .fullscreen, .pause {
    font-weight: bold;
    margin: 0 10px;
    font-size: 20px;
  }
  .play {
    cursor: pointer;
    color: red;
  }
  .pause {
    cursor: pointer;
    color: $green;
  }
  .fullscreen {
    cursor: pointer;
    color: blue;
  }
}